import {
  AdePlayerEventMap,
  ContentItem,
  PlayerPayload,
  SlowPlayerState,
} from '@adiffengine/engine-types'

import {
  AdvancedBoxCard,
  AdvancedGridCardTypes,
  MediaStillHereConfig,
  MediaStillHereStates,
  StillHereMediaMonitor,
} from '../components'

import { Lightning, Router, VideoPlayer } from '@lightningjs/sdk'
import { Debugger, defaultBackHandler } from '../lib'

import { ThorError } from '../lib'

const debug = new Debugger('AdePlayerPage')

export interface AdePlayerPageTemplateSpec
  extends Lightning.Component.TemplateSpec {
  cardType: AdvancedGridCardTypes
}

export interface AdePlayerPageTypeConfig
  extends Lightning.Component.TypeConfig {
  EventMapType: AdePlayerEventMap
  IsPage: true
}

export class AdePlayerPage extends Lightning.Component<
  AdePlayerPageTemplateSpec,
  AdePlayerPageTypeConfig
> {
  private _content: ContentItem | undefined
  static override _template(): Lightning.Component.Template<AdePlayerPageTemplateSpec> {
    return {
      x: 0,
      y: 0,
      h: 1080,
      w: 1920,
    }
  }
  public cardType = AdvancedBoxCard
  override _onChanged(): void {
    Router.focusWidget('VideoPlayer')
  }

  updateHistory(contentItem: ContentItem | null) {
    this.initialMonitorConfig()
    if (contentItem === null) return
    this._content = contentItem
    const history = Router.getHistory()
    if (history.length > 0) {
      const last = history[history.length - 1]
      if (last?.hash.replace(/^\//, '').indexOf('details') === 0) {
        const historyClone = JSON.parse(JSON.stringify(history))
        historyClone[historyClone.length - 1].hash =
          contentItem.paths.details.replace(/^\//, '')
        Router.setHistory(historyClone)
      }
    }
  }

  setPlayerPayload(p: PlayerPayload) {
    console.info('Player Payload', p)
    this._content = p?.content ?? null
    this.fireAncestors('$setPlayerPayload', p)
  }

  override _handleBack() {
    VideoPlayer.close()
    Router.focusPage()
    const handled = defaultBackHandler(
      this.application,
      this._content?.paths.details
    )
    if (!handled) {
      Router.back()
    }
  }

  initialMonitorConfig(): Partial<MediaStillHereConfig> {
    const currentItem = this.fireAncestors('$getPlayerPayload')
    const currentState = this.fireAncestors('$getPlayerState')
    if (currentItem === null) {
      return {
        currentState: MediaStillHereStates.MEDIA_INACTIVE,
      }
    } else {
      return {
        pauseTimeoutInMinutes: 0.2,
        currentState: currentState.paused
          ? MediaStillHereStates.MEDIA_PAUSED
          : MediaStillHereStates.MEDIA_PLAYING,
      }
    }
  }

  pauseCheck() {
    debug.info('Pause Check')
  }
  _mediaMonitor: StillHereMediaMonitor | null = null
  get mediaMonitor(): StillHereMediaMonitor {
    if (this._mediaMonitor === null) {
      this._mediaMonitor = new StillHereMediaMonitor(
        this.initialMonitorConfig()
      )
      this._mediaMonitor.on('needsPauseCheck', this.pauseCheck.bind(this))
    }
    return this._mediaMonitor
  }

  _playerStateListener(playerState: SlowPlayerState) {
    if (playerState.paused) {
      this.mediaMonitor.mediaPaused()
    } else {
      this.mediaMonitor.mediaPlaying()
    }
  }
  handlePlayerError(error: ThorError) {
    debug.info('Handling player error', error)
    this.fireAncestors('$displayErrorModal', error)
  }
  public page = 'Video Player Page'
  override _active() {
    this.handlePlayerError = this.handlePlayerError.bind(this)
    this.stage.application.on('playerError', this.handlePlayerError)
    this.fireAncestors('$displayVideo', true)
    this.stage.application.emit('screenView', this.page, 'player_screen')
  }
  override _focus() {
    Router.focusWidget('VideoPlayer')
  }
  override _inactive() {
    this.stage.application.off('playerError', this.handlePlayerError)
    this.fireAncestors('$displayVideo', false)
  }

  override _enable() {
    debug.info('Enabled Ade Player Page')
    this.stage.application.on('lifecycle', ({ to }) => {
      if (to !== 'foreground') this._handleBack()
    })
    // Need to clear this somehow.
    this.updateHistory = this.updateHistory.bind(this)
    this._playerStateListener = this._playerStateListener.bind(this)
    this.stage.application.on('playerContentItemChanged', this.updateHistory)
    this.stage.application.on('playerState', this._playerStateListener)
  }

  override _disable() {
    debug.info('Disabled Ade Player Page')
    this.stage.application.off('playerContentItemChanged', this.updateHistory)
    this.stage.application.off('playerState', this._playerStateListener)
    this.fireAncestors('$setPlayerPayload', null)
  }
}
