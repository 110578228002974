import type { google } from '@alugha/ima'
import { ImaSdk, loadImaSdk } from '@alugha/ima'
import { Settings } from '@lightningjs/sdk'
import Emittery from 'emittery'
import { domSafeDoc } from './helpers'
import { AdLoader, AdsEvents } from './types/adTypes'

// Asynchronous loader for external scripts

declare module '@alugha/ima' {
  namespace google {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace ima {
      interface AdEvent {
        type: google.ima.AdEvent.Type
      }
    }
  }
}

interface ImaAdsConstructorArgs {
  videoPlayer: HTMLVideoElement
  ima: ImaSdk
  adsLoader: google.ima.AdsLoader
  adDisplayContainer: google.ima.AdDisplayContainer
}

export class ImaAds implements AdLoader {
  private _videoPlayer: HTMLVideoElement
  private _ima: ImaSdk
  private _adsLoader: google.ima.AdsLoader
  private _emitter = new Emittery<AdsEvents>()
  private _adDisplayContainer: google.ima.AdDisplayContainer
  static async init(
    videoPlayer: HTMLVideoElement,
    adElement?: HTMLElement,
  ): Promise<ImaAds> {
    const ima = await loadImaSdk()
    const doc = domSafeDoc()
    ima.settings.setVpaidMode(ima.ImaSdkSettings.VpaidMode.DISABLED)
    if (!adElement && doc !== null) {
      console.info('Creating ad Element')
      const created = doc?.querySelector('[x-ade-ima-wrapper=true]')
      console.info('Found old ad element....', created)

      if (created) {
        try {
          console.info('removing old ad element to reset.')
          created.parentNode?.removeChild(created)
        } catch (error) {
          console.error('Error removing existing ad div: %s', error.message)
        }
      }
      const stage = Settings.get('app', 'stage')
      adElement = doc.createElement('div')
      adElement.setAttribute('x-ade-ima-wrapper', 'true')
      adElement.setAttribute('width', stage.w)
      adElement.setAttribute('height', stage.h)
      adElement.setAttribute(
        'style',
        `margin:0;position:absolute;top:0;left:0;z-index:10`,
      )
      videoPlayer.after(adElement)
    }
    console.info('Creating ad display container with ', adElement, videoPlayer)

    const adDisplayContainer = new ima.AdDisplayContainer(
      adElement!,
      videoPlayer,
    )

    const adsLoader = new ima.AdsLoader(adDisplayContainer)
    return new ImaAds({
      videoPlayer,
      adsLoader,
      ima,
      adDisplayContainer,
    })
  }
  constructor(param: ImaAdsConstructorArgs) {
    const { videoPlayer, ima, adsLoader, adDisplayContainer } = param
    this._videoPlayer = videoPlayer
    this._ima = ima
    this._adsLoader = adsLoader
    this._adDisplayContainer = adDisplayContainer
    this.onContentPauseRequested = this.onContentPauseRequested.bind(this)
    this.onContentResumeRequested = this.onContentResumeRequested.bind(this)
    this.onAdsManagerLoaded = this.onAdsManagerLoaded.bind(this)
    this.onAdError = this.onAdError.bind(this)
    this.onAdEvent = this.onAdEvent.bind(this)
    this._adsLoader.addEventListener(
      ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
      this.onAdsManagerLoaded,
      false,
    )
    this._adsLoader.addEventListener(
      ima.AdErrorEvent.Type.AD_ERROR,
      this.onAdError,
      false,
    )
  }

  on<T extends keyof AdsEvents>(
    event: T,
    callback: (arg: AdsEvents[T]) => void,
  ) {
    return this._emitter.on(event, callback)
  }
  onAll(
    listener: (
      eventName: keyof AdsEvents,
      eventData: AdsEvents[keyof AdsEvents],
    ) => void | Promise<void>,
  ) {
    return this._emitter.onAny(listener)
  }

  onAdsManagerLoaded(adsManagerLoadedEvent: google.ima.AdsManagerLoadedEvent) {
    const adsRenderingSettings = new this._ima.AdsRenderingSettings()
    adsRenderingSettings.mimeTypes = ['application/vnd.apple.mpegurl']
    this._adsManager = adsManagerLoadedEvent.getAdsManager(
      this._videoPlayer,
      adsRenderingSettings,
    )
  }
  private __adsManager: google.ima.AdsManager | null = null

  private set _adsManager(adsManager: google.ima.AdsManager | null) {
    console.info('Ads Manager Created', adsManager)
    if (adsManager !== this.__adsManager) {
      if (adsManager === null) {
        this.__adsManager?.stop()
        this.__adsManager?.destroy()
      } else {
        // Attach the pause/resume events.
        adsManager.addEventListener(
          this._ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
          this.onContentPauseRequested,
          false,
        )
        adsManager.addEventListener(
          this._ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
          this.onContentResumeRequested,
          false,
        )
        // Handle errors.
        adsManager.addEventListener(
          this._ima.AdErrorEvent.Type.AD_ERROR,
          this.onAdError,
          false,
        )
        const events = [
          this._ima.AdEvent.Type.ALL_ADS_COMPLETED,
          this._ima.AdEvent.Type.COMPLETE,
          this._ima.AdEvent.Type.FIRST_QUARTILE,
          this._ima.AdEvent.Type.LOADED,
          this._ima.AdEvent.Type.MIDPOINT,
          this._ima.AdEvent.Type.PAUSED,
          this._ima.AdEvent.Type.STARTED,
          this._ima.AdEvent.Type.THIRD_QUARTILE,
        ]
        for (const index in events) {
          adsManager.addEventListener(events[index], this.onAdEvent, false)
        }

        const initWidth = this._videoPlayer.width
        const initHeight = this._videoPlayer.height

        console.info('Init Items w: %s h: %s', initWidth, initHeight)

        adsManager.init(
          initWidth,
          initHeight,
          this._ima.ViewMode.NORMAL,
          this._videoPlayer,
        )

        this._adDisplayContainer.initialize()
        adsManager.start()
      }
      this.__adsManager = adsManager
    }
  }
  private get _adsManager(): google.ima.AdsManager | null {
    return this.__adsManager
  }

  onAdError(event: google.ima.AdErrorEvent) {
    const error = event.getError()
    console.info('Ad Error %s', error.getMessage(), error)
    if (this._adsManager) {
      this._adsManager.destroy()
    }
    const innerError = error.getInnerError()
    if (innerError) {
      this._emitter.emit('error', innerError)
    }
  }
  onAdEvent(adEvent: google.ima.AdEvent) {
    console.info('Ad Event: %s', adEvent.type, adEvent)
    if (adEvent.type == this._ima.AdEvent.Type.LOADED) {
      this._emitter.emit('onRequestAdsLoaded')
      const ad = adEvent.getAd()
      if (ad && !ad.isLinear()) {
        this.onContentResumeRequested()
      }
    }
    switch (adEvent.type) {
      case this._ima.AdEvent.Type.LOADED: {
        const ad = adEvent.getAd()
        if (ad && !ad.isLinear()) {
          this.onContentResumeRequested()
        }
        break
      }
      case this._ima.AdEvent.Type.STARTED:
        this._emitter.emit('adStarted')
        break
      case this._ima.AdEvent.Type.COMPLETE:
        this._emitter.emit('adComplete')
        break
      case this._ima.AdEvent.Type.ALL_ADS_COMPLETED:
        this._emitter.emit('allAdsComplete')
        break
    }
  }

  private onContentPauseRequested() {
    this._emitter.emit('onPauseRequested')
  }
  private onContentResumeRequested() {
    this._emitter.emit('onResumeRequested')
  }

  requestAds(adTagUrl: string) {
    return new Promise<void>(resolve => {
      this._emitter.emit('onRequestAds')
      const adsRequest = new this._ima.AdsRequest()
      adsRequest.setAdWillAutoPlay(true)
      adsRequest.setAdWillPlayMuted(true)
      adsRequest.adTagUrl = adTagUrl
      adsRequest.linearAdSlotWidth = this._videoPlayer.width
      adsRequest.linearAdSlotHeight = this._videoPlayer.height
      adsRequest.nonLinearAdSlotWidth = this._videoPlayer.width
      adsRequest.nonLinearAdSlotHeight = this._videoPlayer.height
      this._adsLoader.requestAds(adsRequest)
      let resolved = false
      this._emitter.once('onRequestAdsLoaded').then(() => {
        if (!resolved) {
          console.info('Ad Request Loaded')
          resolved = true
          resolve()
        }
      })
      setTimeout(() => {
        if (!resolved) {
          console.info('Ad Request timedout')
          resolved = true
          resolve()
        }
      }, 1000)
    })
  }

  pause() {
    this._adsManager?.pause()
  }
  resume() {
    this._adsManager?.resume()
  }
  contentEnded() {
    this._emitter.emit('contentComplete')
    this._adsLoader.contentComplete()
  }
  destroy() {
    this._adsManager?.destroy()
  }
}
