import { Lightning } from '@lightningjs/sdk'
import { Debugger } from '../lib'
import { SearchScreenContent } from './SearchScreenContent'
// @ts-ignore
const debug = new Debugger('SearchScreen')
export interface SearchPageTypeConfig extends Lightning.Component.TypeConfig {
  IsPage: true
}

export interface SearchPageTemplateSpec
  extends Lightning.Component.TemplateSpec {
  SearchContent: typeof SearchScreenContent
}

export class SearchPage
  extends Lightning.Component<SearchPageTemplateSpec, SearchPageTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<SearchPageTemplateSpec>
{
  public IsPage = true
  SearchContent = this.getByRef('SearchContent')!
  static override _template(): Lightning.Component.Template<SearchPageTemplateSpec> {
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
      SearchContent: {
        type: SearchScreenContent,
      },
    }
  }
  $widgets() {
    return this.widgets ?? null
  }

  override _getFocused() {
    return this.SearchContent
  }
}
