// @ts-ignore
import mux from 'mux-embed'
import { Settings } from '@lightningjs/sdk'
import Hls, {
  HlsConfig,
  ErrorData as HlsErrorData,
  Events as HlsEvents,
  LevelSwitchedData,
  ManifestParsedData,
  MediaAttachedData,
} from 'hls.js'

import { AdeVastPlayerSetup } from './lib/VastLoader'

import {
  ID,
  VideoEvent,
  VideoRateChangeEvent,
  VideoRenditionChangeEvent,
  VideoViewEvent,
  isVideoRateChangeEvent,
  isVideoRenditionChangeEvent,
} from '@adiffengine/engine-types'
import { MetroPlayer, RmpVast } from '@adiffengine/vast-player'
import equal from 'fast-deep-equal'
import {
  Debugger,
  ThorError,
  convertErrorToThorError,
  isNumber,
} from '../../lib'
import { PlayerEventPayload } from './lib/playerEvents'
import { ADEVastPlayerSources, ADE_CONTAINER_ID } from './lib/types'

const debug = new Debugger('metrics:AdeVastPlayerPlane')

export class AdeSinglePlayerVastPlayerPlane extends AdeVastPlayerSetup {
  override MediaPlayer = MetroPlayer
  override async _startVideo() {
    if (this.videoSrc) {
      MetroPlayer.loader(this.loader.bind(this))
      MetroPlayer.unloader(this.unloader.bind(this))
      MetroPlayer.open(this.videoSrc)
    }
  }

  async loader(src: string, video: HTMLVideoElement) {
    debug.info('Loading new video %s', src)
    // src = 'https://stream.mux.com/zCr7gGReyfltnEy2I9qWsnKndi9ZyLwk.m3u8'
    // const adUrl = null

    this.monitoring(video)
    const adUrl = await this.fireAncestors('$adTagForContent', this.content)
    this.playerSources = {
      src,
      adUrl,
    }
    this.setupTrackers(video)
    video = this.setupPlayerHtml(video, adUrl !== null)
    const isHls = this.videoIsHls(src)
    if (isHls) {
      debug.info('Video is hls')
      await this.setupHls(video, this.playerSources.src)
      debug.info('Hls Setup Done')
    } else {
      video.setAttribute('src', src)
      video.load()
    }

    const playError = (e: Error) => {
      const error = new ThorError(
        'play() call failed ' + e.message,
        ThorError.Type.PlaybackError,
        { orginalError: e }
      )
      this.triggerError(error)
    }

    if (this.playerSources.adUrl) {
      const params: Record<string, any> = {
        ajaxWithCredentials: false,
        autoplay: false,
        debugHlsJS: true,
        useHlsJS: true,
        showControlsForVastPlayer: false,
      }
      if (isHls) {
        params['preAdPlay'] = (element: HTMLVideoElement) => {
          if (element && this._hls && element === this._hls.media) {
            debug.info('Clean up HLS instace')
            element.pause()
            element.currentTime = 0
            this.destroyHlsInstance()
            element.removeAttribute('src')
          }
          return element
        }
        params['restoreContentHandler'] = (
          time: number,
          passedVideoElement?: HTMLVideoElement
        ) => {
          this.allAdsCompleted()
          MetroPlayer.pauseEventListeners()
          const videoElement = passedVideoElement ?? video
          debug.info('Restoring video to %s', time, video)
          if (!videoElement.paused) videoElement.pause()
          this.destroyHlsInstance()
          this.destroyVastInstance()
          if (this.playerSources?.src != null) {
            this.setupHls(videoElement, this.playerSources.src, time)
            videoElement
              .play()
              .then(() => {
                MetroPlayer.resumeEventListeners()
              })
              .catch(playError)
          }
        }
      }

      try {
        this.destroyVastInstance()
        this._rmp = new RmpVast(ADE_CONTAINER_ID, params)
        this._rmp.loadAds(this.playerSources.adUrl)
        Object.entries(this.adEvents()).forEach(([event, handler]) => {
          this._rmp.on(event, handler)
        })
      } catch (e) {
        // Create this for the logging
        new ThorError(`Rmp load error ${e.message}`, ThorError.Type.AdError, {
          originalError: e,
        })
        if (this._rmp) this._rmp.destroy()
      }
    }
  }

  async unloader() {
    this.reset()
    MetroPlayer.pause()
    MetroPlayer._videoEl.removeAttribute('src')
    const canvas = this.stage.getCanvas()
    canvas.parentElement?.appendChild(MetroPlayer._videoEl)
    const wrapper = document.querySelector(`#${ADE_CONTAINER_ID}`)
    if (wrapper) {
      wrapper.parentNode?.removeChild(wrapper)
    }
  }
  override playerSetup() {
    MetroPlayer.position(0, 0)
    MetroPlayer.size(this.w, this.h)
  }

  setupHls(video: HTMLVideoElement, src: string, startTime?: number) {
    this.destroyHlsInstance()
    const hlsOptions: Partial<HlsConfig> = {
      debug: false,
      enableWorker: true,
    }
    if (isNumber(startTime) && startTime > 0) {
      hlsOptions.startPosition = startTime
    }
    this._hls = new Hls(hlsOptions)

    this._hls.on(Hls.Events.MEDIA_ATTACHED, this.mediaAttached.bind(this))
    this._hls.on(Hls.Events.MANIFEST_PARSED, this.manifestParsed.bind(this))
    this._hls.on(Hls.Events.LEVEL_SWITCHED, this.levelSwitched.bind(this))
    this._hls.on(Hls.Events.ERROR, this.handleHlsError.bind(this))
    this._hls.loadSource(src)
    this._hls.attachMedia(video)
  }

  private _playerSources: ADEVastPlayerSources | null = null

  set playerSources(payload: ADEVastPlayerSources | null) {
    // if (payload) payload.src = this.ampUp(payload.src)
    if (!equal(this._playerSources, payload)) {
      this._playerSources = payload
    }
  }

  get playerSources(): ADEVastPlayerSources | null {
    return this._playerSources
  }

  ampUp(src: string) {
    const deviceClass = this.fireAncestors('$deviceClass')
    return deviceClass === 'x1' ? src.replace(/^http/, 'aamp') : src
  }

  videoIsHls(source?: string | null) {
    if (!source) return false
    else if (source.toLowerCase().indexOf('aamp') === 0) return false
    else return /\.m3u8$/.test(source.toLowerCase())
  }

  // * METRICS AND EVENTS * //
  setupTrackers(videoEl: HTMLVideoElement) {
    this.setTracker(videoEl, 'loadstart', this.loadStart.bind(this))
    this.setTracker(videoEl, 'pause', this.pause.bind(this))
    this.setTracker(videoEl, 'play', this.play.bind(this))
    this.setTracker(videoEl, 'progress', this.progress.bind(this))
    this.setTracker(videoEl, 'seeked', this.seeked.bind(this))
    this.setTracker(videoEl, 'waiting', this.waiting.bind(this))
    this.setTracker(videoEl, 'error', this.error.bind(this))
    this.setTracker(videoEl, 'ended', this.ended.bind(this))
  }
  setTracker(
    videoEl: HTMLVideoElement,
    event: string,
    tracker: (...a: any[]) => unknown
  ) {
    videoEl.removeEventListener(event, tracker)
    videoEl.addEventListener(event, tracker)
  }
  loadStart() {
    if (this.content) {
      const event: VideoEvent = {
        video_event: 'load_start',
        video_id: this.content.id,
        video_title: this.content.title,
      }
      this.stage.application.emit('videoTrackingEvent', event)
    }
  }
  _getMediaPosition() {
    const video_duration = MetroPlayer.duration
    const video_position = MetroPlayer.currentTime
    if (isNumber(video_duration) && isNumber(video_position)) {
      const video_progress = video_position / video_duration
      return {
        video_duration,
        video_position,
        video_progress,
      }
    } else if (isNumber(video_duration)) {
      return {
        video_position: 0,
        video_duration,
        video_progress: 0,
      }
    } else if (isNumber(video_position)) {
      return {
        video_position,
      }
    } else {
      return {}
    }
  }

  trackAnalyticsEvent<T extends VideoEvent['video_event']>(
    video_event: VideoEvent['video_event'],
    extra?: T extends 'rendition_change'
      ? VideoRenditionChangeEvent['rendition_data']
      : T extends 'rate_change'
        ? VideoRateChangeEvent['rate_data']
        : undefined
  ): void {
    debug.info('Tracking Analytics Event')
    if (this.content) {
      const event: VideoEvent = {
        video_event,
        video_id: this.content.id,
        video_title: this.content.title,
        ...this._getMediaPosition(),
      }
      if (isVideoRateChangeEvent(event)) {
        event.rate_data = extra as VideoRateChangeEvent['rate_data']
      }

      if (isVideoRenditionChangeEvent(event)) {
        event.rendition_data =
          extra as VideoRenditionChangeEvent['rendition_data']
      }
      this.stage.application.emit('videoTrackingEvent', event)
    }
  }

  _progressEvents: VideoViewEvent['progress'][] = []
  override _construct() {
    super._construct()
    this.stage.application.on('currentPlaybackItem', item => {
      this.clearProgressEvents()
      if (item) {
        const payload: VideoViewEvent = {
          video_id: item.id,
          video_title: item.title,
          progress: '0%',
        }

        this._progressEvents.push('0%')
        this.stage.application.emit('videoViewEvent', payload)
      }
    })
  }
  clearProgressEvents() {
    this._progressEvents = []
  }

  private _sendProgressEvent(progress: VideoViewEvent['progress']) {
    if (this.content && !this._progressEvents.includes(progress)) {
      this._progressEvents.push(progress)
      const payload: VideoViewEvent = {
        video_id: this.content.id,
        video_title: this.content.title,
        progress,
      }
      this.stage.application.emit('videoViewEvent', payload)
    }
  }

  private _currentProgress(progress: number) {
    if (this.content) {
      if (progress > 0 && !this._progressEvents.includes('0%')) {
        this._sendProgressEvent('0%')
      }
      if (progress > 0.25 && !this._progressEvents.includes('25%')) {
        this._sendProgressEvent('25%')
      }
      if (progress > 0.5 && !this._progressEvents.includes('50%')) {
        this._sendProgressEvent('50%')
      }
      if (progress > 0.75 && !this._progressEvents.includes('75%')) {
        this._sendProgressEvent('75%')
      }
      if (progress > 0.95 && !this._progressEvents.includes('100%')) {
        this._sendProgressEvent('100%')
      }
    }
  }
  pause() {
    this.trackAnalyticsEvent('pause')
  }
  play() {
    this.trackAnalyticsEvent('play')
  }

  progress() {
    const video_duration = MetroPlayer.duration
    const video_position = MetroPlayer.currentTime
    if (isNumber(video_duration) && isNumber(video_position)) {
      let video_progress = video_position / video_duration
      video_progress =
        video_progress < 0 ? 0 : video_progress > 1 ? 1 : video_progress
      this._currentProgress(video_progress)
    }
  }

  seeked() {
    this.trackAnalyticsEvent('seeked')
  }
  waiting() {
    this.trackAnalyticsEvent('waiting')
  }
  ended() {
    this.trackAnalyticsEvent('ended')
  }
  error(event: Event) {
    const error = new ThorError(
      'Unknown Playback Error',
      ThorError.Type.PlaybackError,
      { event }
    )
  }

  manifestParsed(event: HlsEvents.MANIFEST_PARSED, data: ManifestParsedData) {
    debug.info(
      'Manifest Parsed, found %s quality levels',
      data.levels.length,
      event
    )
  }
  mediaAttached(event: HlsEvents.MEDIA_ATTACHED, data: MediaAttachedData) {
    debug.info('MediaAtttached', event, data)
  }

  private _currentBitrate: number | null = null
  levelSwitched(event: HlsEvents.LEVEL_SWITCHED, data: LevelSwitchedData) {
    debug.info('Level Switched', event, data)
    const level = this._hls?.levels[this._hls?.currentLevel]
    if (level && this.content) {
      if (this._currentBitrate !== level.bitrate) {
        this._currentBitrate = level.bitrate
        this.trackAnalyticsEvent('rate_change', {
          bitrate: level.bitrate,
        })
      }

      this.trackAnalyticsEvent('rendition_change', {
        bitrate: level.bitrate,
        width: level.width,
        height: level.height,
        codecSet: level.codecSet,
      })
    }
  }

  handleHlsError(event: HlsEvents.ERROR, data: HlsErrorData) {
    this.handleUnrecoverableError = this.handleUnrecoverableError.bind(this)
    if (data.fatal) {
      switch (data.type) {
        case Hls.ErrorTypes.MEDIA_ERROR:
          switch (data.details) {
            case Hls.ErrorDetails.MANIFEST_INCOMPATIBLE_CODECS_ERROR:
              this.handleUnrecoverableError(event, data)
              break
            default:
              if (this._hls) this._hls.recoverMediaError()
              break
          }
          break
        case Hls.ErrorTypes.NETWORK_ERROR:
          switch (data.details) {
            case Hls.ErrorDetails.FRAG_LOAD_ERROR:
              if (this._hls)
                this._hls.currentLevel =
                  data.frag!.start + data.frag!.duration + 0.1
              break
            case Hls.ErrorDetails.MANIFEST_LOAD_ERROR:
              this.handleUnrecoverableError(event, data)
              break
            default:
              if (this._hls) this._hls.startLoad()
              break
          }
          break
        default:
          this.handleUnrecoverableError(event, data)
          break
      }
    }
  }

  triggerError(error: ThorError) {
    const payload: PlayerEventPayload = {
      event: error,
      videoElement: MetroPlayer._videoEl,
    }
    debug.info('Firing Ancestors and player error event', error, this)
    this.fireAncestors('$displayErrorModal', error)
    MetroPlayer._consumer.fire(
      '$videoPlayerEvent',
      'Error',
      payload,
      MetroPlayer.currentTime
    )
  }
  handleUnrecoverableError(
    errorEvent: typeof Hls.Events.ERROR,
    data: HlsErrorData
  ) {
    debug.info('Unrecoverable error', errorEvent, data)
    if (this._rmp) {
      debug.info('Destroying Vast Instance')
      this.destroyVastInstance()
    }
    if (MetroPlayer._consumer && this._hls) {
      const error = convertErrorToThorError(
        data.error,
        ThorError.Type.PlaybackError
      )
      this.triggerError(error)
    }
    this.destroyHlsInstance()
  }

  monitoring(video: HTMLVideoElement) {
    const APP_MUX_ENV_KEY = Settings.get('app', 'APP_MUX_ENV_KEY')
    const APP_MUX_DEBUG = Settings.get('app', 'APP_MUX_DEBUG')
    const APP_SHORT_NAME = Settings.get(
      'app',
      'APP_SHORT_NAME',
      'lightning-engine'
    )
    if (APP_MUX_ENV_KEY) {
      debug.info('Attaching Mux Monitoring, ID: %s', APP_MUX_ENV_KEY)
      const playerInitTime = mux.utils.now()
      const data: Record<string, ID> = {
        env_key: APP_MUX_ENV_KEY, // required
        // Metadata fields
        player_name: APP_SHORT_NAME, // any arbitrary string you want to use to identify this player
        player_init_time: playerInitTime,
      }
      if (this.media || this.content) {
        if (this.content) data['video_id'] = this.media?.id ?? this.content.id
        const title = this.content?.title ?? this.media?.button_text
        if (title) data['video_title'] = title
      }
      const setup: Record<string, any> = {
        debug: APP_MUX_DEBUG === true || APP_MUX_DEBUG === 'true', // Never can tell how these get returned
        data,
      }
      mux.monitor(video, setup)
    } else {
      debug.info('Mux Monitoring is not enabled')
    }
  }
}
