import { Colors, Registry, Router } from '@lightningjs/sdk'
import Lightning from '@lightningjs/sdk/src/Lightning'
import { Debugger, ThorError, defaultBackHandler, defer } from '../../lib'
import { AdeErrorMessage } from './AdeErrorScreen'

export interface ErrorModalTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Background: object
  ErrorMessage: typeof AdeErrorMessage
  error: ThorError | null
  vignetteOpacity: number
  modalBackgroundOpacity: number
}
const debug = new Debugger('ThorErrorModal')
export class ThorErrorModal
  extends Lightning.Component<ErrorModalTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<ErrorModalTemplateSpec>
{
  static defaultVignetteOpacity = 0.8
  Background = this.getByRef('Background')!
  ErrorMessage = this.getByRef('ErrorMessage')!
  static override _template(): Lightning.Component.Template<ErrorModalTemplateSpec> {
    return {
      alpha: 0,
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
      Background: {
        x: 0,
        y: 0,
        w: 1920,
        h: 1080,
        rect: true,
        rtt: true,
        color: Colors('background').alpha(this.defaultVignetteOpacity).get(),
      },
      ErrorMessage: {
        x: (1920 - AdeErrorMessage.width) / 2,
        y: (1080 - AdeErrorMessage.height) / 2,
        w: AdeErrorMessage.width,
        h: AdeErrorMessage.height,
        type: AdeErrorMessage,
      },
    }
  }
  private _error: ThorError | null = null
  set error(error: ThorError | null) {
    this.ErrorMessage.patch({
      error,
    })
    this._error = error
  }
  get error(): ThorError | null {
    return this._error
  }
  override _getFocused() {
    return this.ErrorMessage
  }
  override _firstActive(): void {
    // this.ErrorMessage.patch({
    //   buttons: [
    //     {
    //       buttonId: 'back',
    //       buttonText: 'Go Back',
    //       selected: this.goBack.bind(this),
    //     },
    //     {
    //       buttonId: 'home',
    //       buttonText: 'Go Home',
    //       selected: this.goHome.bind(this),
    //     },
    //   ],
    // })
  }
  setButtons() {
    const history = Router.getHistory()
    debug.info('History Length', history)
    const buttons = [
      {
        buttonId: 'home',
        buttonText: 'Go Home',
        selected: this.goHome.bind(this),
      },
    ]
    if (history.length > 0) {
      buttons.unshift({
        buttonId: 'back',
        buttonText: 'Go Back',
        selected: this.goBack.bind(this),
      })
    }
    debug.info('Setting buttons', buttons)
    this.ErrorMessage.patch({
      buttons,
    })
  }
  override _focus(): void {
    this.setButtons()
    this.setSmooth('alpha', 1)
  }
  override _unfocus(): void {
    this.setSmooth('alpha', 0)
  }

  set vignetteOpacity(opacity: number) {
    if (opacity >= 0 && opacity <= 1) {
      this.Background.patch({
        color: Colors('background').alpha(opacity).get(),
      })
    } else {
      console.warn('Invalid vignette opacity %s', opacity)
    }
  }
  set modalBackgroundOpacity(backgroundAlpha: number) {
    if (backgroundAlpha >= 0 && backgroundAlpha <= 1) {
      this.ErrorMessage.patch({
        backgroundAlpha,
      })
    } else {
      console.warn('Invalid vignette opacity %s', backgroundAlpha)
    }
  }
  // A bit of a hack, not getting focus to return properly when
  // navigating here. So making sure the current page gets focus when
  // we return
  hashCheck(startingHash: string, count = 0) {
    if (count < 10) {
      const currentHash = Router.getActiveHash()
      if (currentHash !== startingHash) {
        Router.focusPage()
      } else {
        Registry.setTimeout(() => {
          this.hashCheck(startingHash, count++)
        }, 100)
      }
    } else {
      console.warn("Can't get hash to update.")
    }
  }
  handleButton(action: () => void) {
    const startingHash = Router.getActiveHash()
    Router.focusPage()
    action()
    Registry.setTimeout(() => {
      this.hashCheck(startingHash ?? '')
    }, 100)
  }

  goHome() {
    this.handleButton(() => {
      this.fireAncestors('$navigate', 'home')
    })
  }
  goBack() {
    this.handleButton(() => {
      Router.back()
    })
  }
}
