import {
  ContentItem,
  DirectionalSignalMap,
  isExtra,
  MediaDetails,
} from '@adiffengine/engine-types'
import { Colors, Lightning } from '@lightningjs/sdk'
import isEqual from 'fast-deep-equal/es6'
import isString from 'lodash-es/isString'
import {
  cp,
  Debugger,
  isGoodNumber,
  msLessThanHour,
  prettyTime,
} from '../../lib'
import { getStoredTheme } from '../../themes'
import { AdePlayControls } from './AdePlayControls'
import { ProgressTab } from './ProgressTab'
const debug = new Debugger('Progress')
export const PROGRESS_HEIGHT = 66 + 12 + AdePlayControls.height
export const PROGRESS_WIDTH = 1920

export interface ProgressTemplateSpec extends Lightning.Component.TemplateSpec {
  PlayControls: typeof AdePlayControls
  LowerContent: {
    ProgressBar: {
      Progress: object
      ProgressUpdate: object
    }
    ProgressTab: typeof ProgressTab
    CurrentTime: {
      Time: object
      Duration: object
    }
    Details: {
      Title: object
      Extra: object
    }
  }
  title: string
  extra: string
}

export interface ProgressTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: DirectionalSignalMap
}

export class Progress extends Lightning.Component<ProgressTemplateSpec> {
  public static height = PROGRESS_HEIGHT
  public static width = PROGRESS_WIDTH
  PlayControls = this.getByRef('PlayControls')!

  LowerContent = this.getByRef('LowerContent')!

  ProgressBar = this.LowerContent.getByRef('ProgressBar')!
  ProgressTab = this.LowerContent.getByRef('ProgressTab')!
  Progress = this.ProgressBar.getByRef('Progress')!
  ProgressUpdate = this.ProgressBar.getByRef('ProgressUpdate')!
  CurrentTime = this.LowerContent.getByRef('CurrentTime')!
  Time = this.CurrentTime.getByRef('Time')!
  Duration = this.CurrentTime.getByRef('Duration')!
  Details = this.LowerContent.getByRef('Details')!
  Title = this.Details.getByRef('Title')!
  Extra = this.Details.getByRef('Extra')!

  static override _template(): Lightning.Component.Template<ProgressTemplateSpec> {
    const theme = getStoredTheme()

    return {
      w: 1920,
      h: AdePlayControls.height + 12 + 66,
      PlayControls: {
        x: (w: number) => w / 2 - AdePlayControls.width / 2,
        y: 0,
        w: AdePlayControls.width,
        h: AdePlayControls.height,
        type: AdePlayControls,
        signals: {
          down: '_down',
          hovered: '_hovered',
          right: '_right',
          fastForwardPressed: '_fastForwardPressed',
          fastForwardReleased: '_goDefault',
          rewindPressed: '_rewindPressed',
          rewindReleased: '_goDefault',
        },
      },
      LowerContent: {
        y: AdePlayControls.height + 12,
        w: cp,
        h: 66,
        ProgressTab: {
          x: 0,
          y: -12,
          type: ProgressTab,
          mountY: 1,
          mountX: 0.5,
        },
        ProgressBar: {
          x: 0,
          y: 0,
          h: 6,
          w: (x: number) => x,
          rect: true,
          color: Colors('white').alpha(0.2).get(),
          clipping: true,
          shader: { type: Lightning.shaders.RoundedRectangle, radius: 3 },
          Progress: {
            x: 0,
            y: 0,
            h: 6,
            w: 0,
            alpha: 1,
            rect: true,
            color: Colors(theme.palette.highlights[200]).get(),
            shader: { type: Lightning.shaders.RoundedRectangle, radius: 3 },
          },
          ProgressUpdate: {
            x: 0,
            y: 0,
            h: 6,
            w: 0,
            alpha: 0.00001,
            rect: true,
            color: Colors(theme.palette.highlights[700]).get(),
            shader: { type: Lightning.shaders.RoundedRectangle, radius: 3 },
          },
        },
        CurrentTime: {
          x: 0,
          y: 26,
          h: 40,
          w: 200,
          flex: {
            direction: 'row',
            alignItems: 'center',
          },
          Time: {
            visible: true,
            text: {
              fontSize: 18,
              fontFace: 'Bold',
              text: '00:00:00',
            },
          },
          Duration: {
            visible: false,
            text: {
              fontSize: 18,
              fontFace: 'Bold',
              text: '/ 55:44',
            },
          },
        },
        Details: {
          w: (w: number) => w - 440,
          y: 36,
          x: 220,
          flex: {
            direction: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
          },
          Title: {
            color: Colors('text').get(),
            text: {
              fontSize: 24,
              fontFace: 'Bold',
              text: '',
              textAlign: 'center',
              lineHeight: 30,
              wordWrapWidth: 600,
            },
          },
          Extra: {
            visible: false,
            text: {
              fontSize: 18,
              fontFace: 'Bold',
              text: '',
              textAlign: 'center',
            },
          },
        },
      },
    }
  }
  override _getFocused() {
    return this.PlayControls
  }
  private _title: string = ''
  set title(text: string) {
    if (text !== this._title && isString(text)) {
      this.Title.patch({
        text: {
          text: text.trim().toUpperCase(),
        },
      })
    }
    this._title = text
  }
  _right() {
    this.signal('right')
  }
  _down() {
    this.signal('down')
  }
  _hovered() {
    debug.info('Got hovered')
    this.signal('hovered')
  }

  get title() {
    return this._title
  }
  private _extra: string = ''
  set extra(text: string) {
    console.info('Debug Progress Extra %s', text)
    if (text !== this._extra && isString(text)) {
      this.Extra.patch({
        visible: text.trim().length !== 0,
        text: {
          text: text.trim(),
        },
      })
    }
  }
  private _currentTime: number = 0
  currentTime(time: number) {
    if (time !== this._currentTime) {
      this._currentTime = time
      this.Time.patch({
        text: {
          text: `${prettyTime(time)} `,
        },
      })
      this.updateProgress()
    }
  }
  private _duration: number = Infinity
  duration(duration: number) {
    if (duration !== this._duration) {
      this._duration = duration
      this.Duration.patch({
        visible: isGoodNumber(duration) && duration !== Infinity,
        text: {
          text:
            isGoodNumber(duration) && duration !== Infinity
              ? `/ ${prettyTime(duration)}`
              : '/ 00:00',
        },
      })
      this.updateProgress()
    }
  }

  private _currentProgress: number = 0
  updateProgress() {
    this._currentProgress =
      isGoodNumber(this._duration) && this._duration !== Infinity
        ? this._currentTime / this._duration
        : 0
    if (
      isGoodNumber(this._currentProgress) &&
      this._currentProgress < 1 &&
      isGoodNumber(this.finalW)
    ) {
      this.Progress.patch({
        w: this.finalW * this._currentProgress,
      })
    }
    this.restoreProgress()
  }

  contentUpdate(content: typeof this._content) {
    this.content = content
  }

  private _media: MediaDetails | null = null
  mediaUpdate(media: typeof this._media) {
    this.media = media
  }
  set media(media: typeof this._media) {
    if (!isEqual(media, this._media)) {
      this._media = media
      this.extra = isExtra(media) ? media.title : ''
    }
  }
  get media() {
    return this._media
  }

  private _content: ContentItem | null = null

  set content(content: typeof this._content) {
    if (!isEqual(content, this._content)) {
      this.Title.patch({
        text: {
          text: content?.title ?? '',
          maxLines: 1,
          wordWrap: true,
          wordWrapWidth: this.w - 600,
        },
      })
    }
  }
  get content() {
    return this._content
  }

  override _firstActive() {
    this.timeUpdate = this.timeUpdate.bind(this)
    this.stage.application.on('playerTime', this.timeUpdate)
  }
  override _active() {
    this.Details.patch({
      w: this.finalW - 440,
    })
  }
  timeUpdate(time: number, duration: number) {
    this.currentTime(time)
    this.duration(duration)
  }

  override _init() {
    const { duration, currentTime } = this.fireAncestors('$getPlayerState')

    let progress =
      duration !== Infinity && currentTime !== 0 && duration <= currentTime
        ? currentTime / duration
        : 0
    if (progress > 1) progress = 1
    const progressWidth = this.finalW * progress
    this.LowerContent.patch({
      CurrentTime: {
        Time: {
          text: { text: prettyTime(currentTime !== null ? currentTime : 0) },
        },
        Duration: {
          visible: duration !== null,
          text: {
            text: duration !== null ? `/ ${prettyTime(duration)}` : '/ 00:00',
          },
        },
      },
      ProgressBar: {
        Progress: {
          w: progressWidth,
        },
      },
    })

    const currentContent = this.fireAncestors('$getPlayerPayload')
    if (currentContent) {
      const { content, media } = currentContent
      if (content) this.Title.patch({ text: { text: content.title } })
      if (isExtra(media)) {
        this.extra = media.title
      }
    }
  }
  restoreProgress() {
    if (this._restoreProgress) {
      this._restoreProgress = false
      this.Progress.patch({
        alpha: 1,
      })
      this.ProgressUpdate.patch({
        alpha: 0.0001,
      })
      this.ProgressTab.hide(1000)
    }
  }

  private _restoreProgress: boolean = false

  $videoPlayerTimeUpdate(time: number) {
    this.currentTime(time)
  }

  $videoPlayerDurationChange(duration: number) {
    this.duration(duration)
  }

  _fastForwardPressed() {
    this._setState('TrickMode.FastForward')
    return true
  }

  _rewindPressed() {
    debug.info('Rewind Pressed')
    this._setState('TrickMode.Rewind')
  }
  _goDefault() {
    this._setState('Default')
  }

  static override _states(): (typeof Progress)[] {
    return [
      class Default extends this {},
      class TrickMode extends this {
        override updateProgress() {}
        private _advance: number = 0
        private _progressAnimation(forward: boolean, duration: number) {
          debug.info(
            'Setting Progress animation, duration: %s, currentWidth: %s, end: %s',
            duration,
            this.Progress.w,
            forward ? this.finalW : 0
          )

          return this.animation({
            duration,
            actions: [
              {
                t: 'ProgressUpdate',
                p: 'w',
                v: { 0: this.Progress.w, 1: forward ? this.finalW : 0 },
              },
              {
                t: 'ProgressTab',
                p: 'x',
                v: { 0: this.Progress.w, 1: forward ? this.finalW : 0 },
              },
            ],
          })
        }
        private _animation: Lightning.types.Animation | null = null

        private _timeLeft: number = 0
        override $enter() {
          if (this._duration !== Infinity) {
            this._restoreProgress = false
            this.ProgressTab.show()
            this._advance = 0
            this.ProgressUpdate.patch({ w: this.Progress.w })
            this.ProgressUpdate.setSmooth('alpha', 1)
            this.Progress.patch({
              alpha: 0.0001,
            })
          } else {
            this._setState('Default')
          }
        }
        override $exit() {
          const theme = this.fireAncestors('$theme')
          this.Progress.setSmooth(
            'color',
            Colors(theme.palette.highlights[200]).get()
          )
          this._restoreProgress = true
          debug.info('Jumping to time %s', this._advance)
          this.fireAncestors('$jumpToTime', this._advance)
          this._animation?.pause()
        }
        handleAnimationProgress(p: number) {
          const progressWidthPercent = this.ProgressUpdate.w / this.finalW

          const percentTime = this._currentTime + this._timeLeft * p
          let percentDone = percentTime / this._duration

          if (percentDone < 0) percentDone = 0
          if (percentDone > 1) percentDone = 1
          const updateTime = this._duration * progressWidthPercent
          // const updateTime = this._currentTime + this._timeLeft * p
          this.ProgressTab.setTime(
            `${prettyTime(updateTime, {
              showZeroHour: msLessThanHour(this._duration),
            })}/${prettyTime(this._duration, {
              showZeroHour: false,
            })}`
          )
          this._advance = updateTime
        }
        static override _states(): (typeof TrickMode)[] {
          return [
            class FastForward extends this {
              override $enter() {
                this._timeLeft = this._duration - this._currentTime
                const duration = 10 * (this._timeLeft / this._duration)
                this._animation = this._progressAnimation(true, duration)
                this._animation.on(
                  'progress',
                  this.handleAnimationProgress.bind(this)
                )
                this._animation.start()
              }
              override $exit() {
                this._animation?.off('progress', this.handleAnimationProgress)
                super.$exit()
              }
            },
            class Rewind extends this {
              override $enter() {
                const duration = 5 * (this._currentTime / this._duration)
                this._animation = this._progressAnimation(false, duration)
                this._animation.on(
                  'progress',
                  this.handleAnimationProgress.bind(this)
                )
                this._animation.start()
              }
              override $exit() {
                this._animation?.off('progress', this.handleAnimationProgress)
                super.$exit()
              }
            },
          ]
        }
      },
    ]
  }
}
