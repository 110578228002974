import { Debugger } from '@adiffengine/elements'
import {
  AdParameters,
  ContentItem,
  ContentSource,
  GridContext,
  GridResponse,
  MituConfig,
  TheGridRowData,
} from '@adiffengine/engine-types'
import {
  MediaType,
  MrssApiClient,
  MrssFeedItem,
  mediaTypeLookup,
} from '@adiffengine/mrss-sdk'
import { Parameters } from '@firebolt-js/sdk'
import { Settings } from '@lightningjs/sdk'

import qs from 'query-string'
const debug = new Debugger('mitu-sdk')
const APP_NAME = 'mitu-x1'
const APP_BUNDLE = `tv.mitu.x1`
const APP_STORE_URL = ''
const POD_MAX_DURATION = 30
const POD_AD_SLOTS = 2
// test
export interface MituSdk extends MrssApiClient {
  adTag(
    contentItem: ContentItem,
    adParameters: Pick<Parameters.AppInitialization, 'us_privacy' | 'lmt'>
  ): Promise<string | null>
}

export function isContentItemOrNull(x: ContentItem | null): x is ContentItem {
  return x !== null
}

export function isHeroLookupOrNull(x: HeroLookup | null): x is HeroLookup {
  return x !== null
}

interface HeroLookup {
  item: ContentItem
  weight: number
}

export class MituSdk extends MrssApiClient {
  async adTag(
    _contentItem: ContentItem,
    adParameters: AdParameters
  ): Promise<string | null> {
    const params: Record<string, string | number | undefined> = {
      cb: new Date().getTime(),
      w: 1920,
      h: 1080,
      ua: navigator.userAgent,
      app_bundle: APP_BUNDLE,
      app_name: APP_NAME,
      app_store_url: APP_STORE_URL,
      did: adParameters.uid,
      pod_max_duration: POD_MAX_DURATION,
      pod_ad_slots: POD_AD_SLOTS,
      us_privacy: adParameters.us_privacy,
      lmt: adParameters.lmt,
    }
    const clean = Object.entries(params).reduce(
      (acc, [key, value]) => {
        if (value != null) {
          acc[key] = value
        }
        return acc
      },
      {} as Record<string, string | number>
    )

    const tag = `https://tv.springserve.com/vast/717015?${qs.stringify(clean)}`
    return tag
  }

  private _config: MituConfig | null = null

  async getConfig<T extends keyof MituConfig>(
    type: T
  ): Promise<MituConfig[T] | null> {
    if (this._config === null) {
      try {
        const response = await fetch(
          'https://mitu-api.adeengine.com/mitu/config'
        )

        this._config = (await response.json()) as MituConfig
      } catch (error) {
        console.warn('Error fetching config %s', error.message, error)
      }
    }
    return this._config ? this._config[type] : null
  }
  async getPromotedHeros(): Promise<TheGridRowData | null> {
    const config = await this.getConfig('promotedContent')

    if (Array.isArray(config) && config.length) {
      const promotedHeros = config.filter(
        f => f.promotedContentType === 'content-item'
      )
      const heros = await Promise.all(
        promotedHeros.map(
          async ({ externalId, weight = -1 }): Promise<HeroLookup | null> => {
            try {
              const hero = await this.details(externalId)
              return {
                item: hero,
                weight,
              }
            } catch (e) {
              debug.info(
                'Could not find hero for id %s error: %s',
                externalId,
                e.message,
                {
                  error: e,
                }
              )
              return null
            }
          }
        )
      )
      // Testing if this works.
      const items = heros
        .filter<HeroLookup>(isHeroLookupOrNull)
        .sort((a, b) => {
          return b.weight - a.weight
        })
      if (items.length > 0) {
        return {
          id: 'promoted_heros',
          title: 'Promoted Content',
          items: items.map(({ item }) => item),
        }
      }
    }
    return null
  }
  async getPromotedCategories(
    rows: TheGridRowData[]
  ): Promise<TheGridRowData[]> {
    const config = await this.getConfig('promotedContent')
    if (Array.isArray(config) && config.length) {
      const promotedCategories = config.filter(
        f => f.promotedContentType === 'category'
      )
      const ids = promotedCategories.map(({ externalId }) => `${externalId}`)
      const weights = promotedCategories.reduce(
        (acc, { weight = -1, externalId }) => {
          acc[externalId] = weight
          return acc
        },
        {} as Record<string, number>
      )
      const promoted = rows
        .filter(({ id }) => ids.includes(`${id}`))
        .sort((a, b) => {
          const aWeight = weights[a.id] ?? -1
          const bWeight = weights[b.id] ?? -1
          return bWeight - aWeight
        })
      const regular = rows.filter(({ id }) => !ids.includes(`${id}`))
      return [...promoted, ...regular]
    }
    return rows
  }
  async grid(context?: GridContext): Promise<GridResponse> {
    const [response, heros] = await Promise.all([
      super.grid(context),
      this.getPromotedHeros(),
    ])
    if (heros !== null) {
      response.heros = heros
    }
    response.grid = await this.getPromotedCategories(response.grid)
    debug.info('grid', response.grid)
    return response
  }
}
let mituSdk: MituSdk

export function getMituSdk(): MituSdk {
  if (mituSdk) return mituSdk
  // mituSdk = new MituSdk(Settings.get('app', 'http://example.com', ''), {
  const url = Settings.get('app', 'api_url', '')
  mituSdk = new MituSdk(url, {
    filterContentWithNoMedia: true,
    parseSources: (item: MrssFeedItem) => {
      const hls = item.media.filter(i => i.type === MediaType.VideoHLS)
      return hls
        .map(item => {
          const type = mediaTypeLookup(item.type)
          if (type === null) return null
          return {
            type,
            src: item.url,
          }
        })
        .filter(f => {
          return f !== null
        }) as ContentSource[]
    },
  })

  return mituSdk
}
