import * as Sentry from '@sentry/browser'
import { Settings } from '@lightningjs/sdk'
// import { Debugger } from '../debugger'
// const debug = new Debugger('Sentry')

export interface SentryOptionsWithSettings {
  id?: string
  tracePropagationTargets?: string[]
  lightningSettings: true
}
export interface SentryOptionsWithoutSettings {
  id?: string
  tracePropagationTargets?: string[]
  lightningSettings: false
  dsn: string
  tracesSampleRate: number
  env: string
}

export type SentryOptions =
  | SentryOptionsWithSettings
  | SentryOptionsWithoutSettings
export function isSentryOptionsWithoutSettings(
  x: SentryOptions
): x is SentryOptionsWithoutSettings {
  return x.lightningSettings === false
}
let sentryAlreadyEnabled: SentryOptions | null = null
export async function enableSentry(settings: SentryOptions) {
  if (sentryAlreadyEnabled !== null) {
    console.info('Sentry already enabled with', sentryAlreadyEnabled)
  }
  const tracePropagationTargets = ['localhost', /^\//]
  let dsn: string = ''
  let tracesSampleRate = 0.2
  let environment = 'production'
  if (isSentryOptionsWithoutSettings(settings)) {
    dsn = settings.dsn
    tracesSampleRate = settings.tracesSampleRate
    environment = settings.env
  } else {
    dsn = Settings.get('app', 'SENTRY_DSN')
    tracesSampleRate = Settings.get('app', 'SENTRY_TRACE_SAMPLE_RATE', 1)
    environment = Settings.get('app', 'environment', 'production')
  }
  if (dsn) {
    Sentry.init({
      dsn,
      integrations: [new Sentry.BrowserTracing({ tracePropagationTargets })],
      environment,
      // Performance Monitoring
      tracesSampleRate, // Capture 100% of the transactions, reduce in production!
      // Session Replay
    })

    if (settings.id) Sentry.setUser({ id: settings.id })
    sentryAlreadyEnabled = {
      lightningSettings: settings.lightningSettings,
      dsn,
      env: environment,
      tracesSampleRate,
    }
  } else {
    console.warn('No sentry DSN so not enabling.')
  }
}
