/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AdeCloseReason,
  AppTemplateConfig,
  AuthStatus,
  ContentItem,
  ContentSource,
  DeviceLinkPoller,
  FontConfig,
  ID,
  ListPosition,
  MediaDetails,
  MediaLookupType,
  MouseCoordinateDimensions,
  NavigationPayload,
  Palette,
  PlayerPayload,
  PlayerState,
  PointerState,
  PollerResponse,
  SlowPlayerState,
  StoreDeviceLink,
} from '@adiffengine/engine-types'

import { Lightning, Router, Settings, Utils } from '@lightningjs/sdk'

import equal from 'fast-deep-equal/es6'
import isString from 'lodash-es/isString'
import { HoverTarget } from './augmentation'
import {
  AdePlayerPlane,
  AdeSinglePlayerVastPlayerPlane,
  AdvancedWideCard,
  ConfirmModal,
  HeroWidget,
  MainMenu,
  ThorErrorModal,
} from './components'
import {
  UserActivityState,
  UserKeyMonitor,
} from './components/libs/UserKeyMonitor'
import {
  AdeLifecycleHelper,
  Debugger,
  LifeCyclePreStartFunction,
  LifecycleBreadcrumbArg,
  ThorError,
  convertErrorToThorError,
  defer,
  delay,
  enableSentry,
  getCoordinateDimensions,
  getHoverablePath,
  isGoodString,
  isSeachIntentBootResponse,
  splitAndLowerPath,
  testCollision,
} from './lib'
import { PointerHelper } from './lib/pointerHelper'
import { ThorAppTemplateSpec } from './thor-app-types'
import { AreYouStillThere, EndCard, ManualAreYouStillThere } from './widgets'

const debug = new Debugger('ThorApp')

export abstract class ThorApp
  extends Router.App<ThorAppTemplateSpec, AppTemplateConfig>
  implements Lightning.Component.ImplementTemplateSpec<ThorAppTemplateSpec>
{
  static getFonts(fonts: FontConfig) {
    return Object.entries(fonts)
      .filter(([family]) => family !== 'name')
      .map(([family, url]: [string, string]) => ({
        family,
        url: Utils.asset(url),
      }))
  }
  override get id() {
    return 'ThorApp'
  }

  ConfirmModal = this.getByRef('Widgets')!.getByRef('ConfirmModal')!
  Widgets = this.getByRef('Widgets')!
  HeroWidget = this.Widgets.getByRef('HeroWidget')!
  VideoPlayer = this.Widgets.getByRef('VideoPlayer')!
  Background = this.getByRef('Background')!
  MainMenu = this.Widgets.getByRef('MainMenu')!
  ErrorModal = this.Widgets.getByRef('ErrorModal')!
  AreYouStillThere = this.Widgets.getByRef('AreYouStillThere')!
  ManualAreYouStillThere = this.Widgets.getByRef('ManualAreYouStillThere')!
  static override _template() {
    return {
      Widgets: {
        x: 0,
        y: 0,
        w: 1920,
        h: 1080,
        alpha: 0.0001,
        VideoPlayer: {
          x: 0,
          y: 0,
          w: 1920,
          h: 1080,
          type: AdeSinglePlayerVastPlayerPlane,
          // type: AdePlayerPlane,
          moreCardType: AdvancedWideCard,
        },

        HeroWidget: {
          x: 0,
          y: 0,
          w: 1920,
          type: HeroWidget,
        },
        MainMenu: {
          type: MainMenu,
          x: 80,
          y: 80,
          w: MainMenu.widthClosed,
          h: 1080 - 160,
          zIndex: 10,
          signals: {
            right: '_menuRight',
          },
        },
        ErrorModal: {
          zIndex: 0,
          type: ThorErrorModal,
          vignetteOpacity: 0.4,
          modalBackgroundOpacity: 0.95,
        },
        EndCard: {
          type: EndCard,
        },
        ConfirmModal: {
          zIndex: 0,
          type: ConfirmModal,
        },

        AreYouStillThere: {
          type: AreYouStillThere,
          zIndex: 0,
        },

        ManualAreYouStillThere: {
          type: ManualAreYouStillThere,
          zIndex: 0,
        },
      },
      ...super._template(),
    }
  }

  static colors(palette: Palette) {
    const colors = Object.entries(palette).reduce(
      (acc, [k, v]) => {
        if (isString(k) && isString(v)) acc[k] = v
        return acc
      },
      {} as Record<string, string>
    )
    return colors
  }

  private _lifecycleHandler: AdeLifecycleHelper | null = null
  get lifecycleHandler(): AdeLifecycleHelper {
    if (this._lifecycleHandler == null) {
      this._lifecycleHandler = new AdeLifecycleHelper(this)
      this._lifecycleHandler.on('state', stateUpdate => {
        this.stage.application.emit('lifecycle', stateUpdate)
      })
    }
    return this._lifecycleHandler
  }

  _mouseCoordinates: MouseCoordinateDimensions = {
    x: 0,
    y: 0,
    height: 80,
    width: 120,
    clientX: 60,
    clientY: 40,
  }

  _menuRight() {
    Router.focusPage()
  }

  _hackHoverForCoordinates() {
    this.stage.application._withinClickableRange = (
      affectedChildren: Lightning.Element[],
      cursorX: number,
      cursorY: number
    ) => {
      let n = affectedChildren.length
      const candidates = []

      // loop through affected children
      // and perform collision detection
      while (n--) {
        const child = affectedChildren[n]

        const precision =
          this.stage.getRenderPrecision() /
          this.stage.getOption('devicePixelRatio')
        const ctx = child.core._worldContext
        const cx = ctx.px * precision
        const cy = ctx.py * precision
        const cw = child.finalW * ctx.ta * precision
        const ch = child.finalH * ctx.td * precision

        if (cx > this.stage.w || cy > this.stage.h) {
          continue
        }
        if (testCollision(cursorX, cursorY, cx, cw, cy, ch)) {
          candidates.push(child)
        }
      }
      return candidates
    }
    const original = this.stage.application._receiveHover.bind(
      this.stage.application
    )
    this.stage.application._receiveHover = (evt: MouseEvent) => {
      const { clientX, clientY } = evt
      if (clientX <= this.stage.w && clientY <= this.stage.h) {
        this._mouseCoordinates = {
          ...this._mouseCoordinates,
          x: Math.round(clientX - this._mouseCoordinates.width / 2),
          y: Math.round(clientX - this._mouseCoordinates.height / 2),
        }
      }
      return original(evt)
    }
  }
  _currentHover: HoverTarget | null = null

  _handleMainMenuHover(on: boolean) {
    debug.info('Handling on main menu hover', on)
    if (on) {
      const widget = Router.getActiveWidget()
      debug.info('on widget', widget)
      if (widget !== this.MainMenu) {
        this.MainMenu.setClosestByY(this._mouseCoordinates)
        Router.focusWidget('MainMenu')
      }
    } else {
      const widget = Router.getActiveWidget()
      debug.info('off main menu', widget)
      if (widget === this.MainMenu) {
        Router.focusPage()
      }
    }
  }

  private _onMainMenu: boolean = false
  private set onMainMenu(on: boolean) {
    debug.info('setting onMainMenu to %s from %s', on, this._onMainMenu)
    if (this._onMainMenu !== on) {
      this._onMainMenu = on
      this._handleMainMenuHover(on)
    }
  }
  private get onMainMenu() {
    return this._onMainMenu
  }
  private _lastHover: string = ''
  $hovered(component: Lightning.Component) {
    const paths = getHoverablePath(component)
    const hoverPath = splitAndLowerPath(paths).join('/')
    debug.info('Hover', hoverPath)
    if (hoverPath !== this._lastHover) {
      const split = splitAndLowerPath(paths).map(x => x.split('::')[0])
      this.onMainMenu = split.includes('mainmenu')
      this.stage.application.emit(
        'hovered',
        paths,
        getCoordinateDimensions(component)
      )
      this._lastHover = hoverPath
    }
  }
  $enableMainMenuPointer(enable: boolean) {
    this.MainMenu.patch({
      needsScroll: enable,
    })
  }

  $mouseCoordinates() {
    return this._mouseCoordinates
  }
  override _captureKey() {
    this._userActivityMonitor?.ping()
    return false
  }

  override async _setup() {
    debug.info('setup on thor', this)
    this._hackHoverForCoordinates()
    let routeConfig = this.getRouteConfig('home')
    try {
      this._monitorUserActivity()
      enableSentry({ lightningSettings: true })
      const boot = await this.lifecycleHandler.boot()
      const { path, params } = boot
      debug.info('Boot Path: %s', path, params)
      routeConfig = this.getRouteConfig(path)
      if (isSeachIntentBootResponse(boot) && isGoodString(params['query'])) {
        this.setInitialQuery(params['query'])
      }
    } catch (error) {
      convertErrorToThorError(error) // This captures the error for handling
    }
    try {
      debug.info('Starting Router')

      Router.startRouter(routeConfig, this)
    } catch (error) {
      convertErrorToThorError(error)
    }
    if (typeof window !== 'undefined') {
      window.__ROUTER = Router
      window.__THOR_APP = this
    }
  }
  private _pointerInstance: PointerHelper | null = null
  set _pointerHelper(helper: PointerHelper | null) {
    if (this._pointerInstance) this._pointerInstance.destroy()
    if (helper) {
      this._pointerInstance = helper
      this._pointerInstance.on('state', (state: PointerState) => {
        debug.info('Pointer State', state)
        this.stage.application.emit('pointerState', state)
      })
    }
  }
  $pointerState() {
    return this._pointerInstance ? this._pointerInstance.state : 'disabled'
  }
  override _init() {
    this._pointerHelper = new PointerHelper()
    if (this._displayVideo) this.Background?.patch({ visible: false })
    this.stage.application.on('menuOpen', (open: boolean) => {
      if (this._menuOpen !== open) {
        this._menuOpen = open
      }
    })
    this.setPlayerState = this.setPlayerState.bind(this)
    this.stage.application.on('playerState', this.setPlayerState)
  }

  // Must explicitly pass null as a value to the initial hash.
  abstract getRouteConfig(
    initialHash: string | null,
    params?: Router.PageParams
  ): Router.Config
  public prestart: LifeCyclePreStartFunction | undefined = undefined
  private _currentError: Error | null = null

  $currentError(error?: Error | null): Error | null {
    if (error) {
      console.error('Current Error Set to %s', error.message, { error })
      this.lifecycleHandler.error(error.message, error)
      this._currentError = error
    } else if (error === null) {
      const current = this._currentError
      this._currentError = null
      return current
    }
    return this._currentError
  }
  $deviceClass(): 'x1' | 'browser' | 'lg' | 'samsung' | 'unknown' {
    const deviceInfo = this.lifecycleHandler.deviceInfo
    if (
      deviceInfo !== null &&
      (deviceInfo.distributor === 'Comcast' ||
        deviceInfo.model.indexOf('xi') > -1)
    ) {
      return 'x1'
    } else {
      return 'unknown'
    }
  }
  private _displayVideo: boolean = false
  set displayVideo(arg: boolean) {
    debug.info('Displaying video?', arg, this._displayVideo)
    if (this._displayVideo !== arg) {
      this._displayVideo = arg
      debug.info(' Video Visible?', arg)
      this.Background?.setSmooth('alpha', arg ? 0 : 1, { duration: 0.2 })
    }
  }
  get displayVideo() {
    return this._displayVideo
  }

  async $adTagForContent(_content: ContentItem): Promise<string | null> {
    debug.warn('Ad tag for content is not implemented')
    return null
  }
  private _initialQuery = ''

  setInitialQuery(q: string) {
    this._initialQuery = q
  }

  $initialSearchQuery(clear = true): string | null {
    if (isGoodString(this._initialQuery)) {
      const response = this._initialQuery
      if (clear) this._initialQuery = ''
      return response
    } else {
      const params = Router.getQueryStringParams()
      if (params && isGoodString(params['q'])) {
        return params['q']
      }
    }
    return null
  }

  abstract $search(term: string, min?: number): Promise<ContentItem[] | null>

  _listPositionCache: Record<
    string,
    {
      time: number
      position: ListPosition
    }
  > = {}

  async $getDeviceCode(): Promise<StoreDeviceLink> {
    console.warn('Get Device Code is not implemented')
    throw new ThorError(
      '$getDeviceCode is not implemented',
      ThorError.Type.FunctionNotImplemented,
      { function: '$getDeviceCode' }
    )
  }

  private _menuOpen: boolean = false
  $menuOpen(): boolean | null {
    return this._menuOpen
  }

  static validStartingPaths = [
    'home',
    'details',
    'player',
    'search',
    'settings',
  ]

  breadcrumb(arg: LifecycleBreadcrumbArg) {
    this.lifecycleHandler.breadcrumb(arg)
  }

  $navigate(d: string | NavigationPayload, store = true) {
    if (isString(d)) {
      d = d.replace(/^\//, '')
      this.lifecycleHandler.breadcrumb({
        category: 'navigation',
        message: `Navigate to ${d}`,
        level: 'info',
      })
      Router.navigate(
        d,
        {
          _path: d,
        },
        store
      )
      this.application.emit('navigate', d, {}, store)
    } else {
      let { path, ...payload } = d
      path = path.replace(/^\//, '')
      this.lifecycleHandler.breadcrumb({
        category: 'navigation',
        message: `Navigate to ${path}`,
        level: 'info',
      })
      this.application.emit('navigate', path, payload, store)
      Router.navigate(path, { ...payload, _path: path }, store)
    }
  }
  $reload() {
    debug.info('Reloading Router')
    Router.reload()
    delay(() => {
      debug.info('Navigating to home')
      Router.navigate('home')
    }, 2000)
  }

  $fetchSource(
    _contentItem: ContentItem,
    _lookup: MediaLookupType
  ): Promise<MediaDetails | null> {
    throw new ThorError(
      '$fetchSource is not implemented',
      ThorError.Type.FunctionNotImplemented,
      { function: '$fetchSource' }
    )
  }

  private _playerPayload: PlayerPayload | null = null
  $setPlayerPayload(p: PlayerPayload | null): void {
    this._playerPayload = p
    this.VideoPlayer?.setPlayerPayload(p)
  }

  $getPlayerPayload(): PlayerPayload | null {
    return this._playerPayload
  }

  _playerTime = 0
  setPlayerTime(time: number) {
    this._playerTime = time
  }
  private _playerState: PlayerState = AdePlayerPlane.defaultPlayerState
  setPlayerState(state: PlayerState) {
    this.playerState = state
  }

  set playerState(state: SlowPlayerState) {
    const update = { ...this._playerState, ...state }
    debug.info('Player State', state, update)
    if (!equal(this._playerState, update)) {
      debug.info('Player State Updated', state, update)
      const active = update.canPlay || update.adActive
      if (active !== this.displayVideo) {
        this.displayVideo = active
      }
      this._playerState = update
    }
  }

  $getPlayerState() {
    return { ...this._playerState, currentTime: this._playerTime }
  }

  $nextVideo(_item: ContentItem): Promise<ContentItem | null> {
    console.warn('$nextVideo not implemented')
    return Promise.resolve(null)
  }

  $error(error: Error) {
    this.lifecycleHandler.error(error.message, error)
  }

  $getAppropriateSource(_sources: ContentSource[] = []): ContentSource | null {
    console.warn('$getAppropriateSource not implemented')
    return null
  }

  _captureKeyExit() {
    this._confirmAndClose(AdeCloseReason.USER_EXIT)
  }

  private async _confirmAndClose(reason: AdeCloseReason) {
    Router.focusWidget('ConfirmModal')
    const confirmed = await this.ConfirmModal.confirm(
      'Leaving So Soon?',
      'Are you sure you want to exit to the main menu?'
    )
    if (confirmed) {
      this.lifecycleHandler.exit(reason)
    } else {
      debug.info('Exit Not confirmed, so not exiting')
      Router.focusPage()
    }
  }

  async $exitApp(
    reason: AdeCloseReason = AdeCloseReason.USER_EXIT,
    skipCheck = false
  ) {
    if (skipCheck === true) {
      defer(() => {
        this.lifecycleHandler.exit(reason)
      })
    } else {
      defer(async () => {
        this._confirmAndClose(reason)
      })
    }
  }

  override async _handleAppClose(): Promise<void> {
    defer(() => this.$exitApp())
  }

  override _handleExit(e: KeyboardEvent) {
    e.stopPropagation()
    e.preventDefault()
    this.$exitApp(AdeCloseReason.REMOTE_BUTTON)
  }
  public authStatus: AuthStatus = {
    isAnonymous: false,
    status: 'notready',
  }
  $authStatus() {
    return this.authStatus
  }

  _poller: DeviceLinkPoller | null = null
  $pollForCode(
    _deviceId: string,
    _linkCode: string,
    _pollingInterval = 4000
  ): Promise<PollerResponse> {
    throw new ThorError(
      '$pollForCode is not implemented',
      ThorError.Type.FunctionNotImplemented,
      { function: '$pollForCode' }
    )
  }
  $cancelPolling() {
    throw new ThorError(
      '$cancelPolling is not implemented',
      ThorError.Type.FunctionNotImplemented,
      { function: '$cancelPolling' }
    )
  }

  $setting(_key: string): any {
    throw new ThorError(
      '$setting is not implemented',
      ThorError.Type.FunctionNotImplemented,
      { function: '$setting' }
    )
  }
  $fetchSeason(_id: ID, _number?: ID) {
    throw new ThorError(
      '$fetchSeason is not implemented',
      ThorError.Type.FunctionNotImplemented,
      { function: '$fetchSeason' }
    )
  }

  $fetchPerson(_id: ID) {
    throw new ThorError(
      '$fetchPerson is not implemented',
      ThorError.Type.FunctionNotImplemented,
      { function: '$fetchPerson' }
    )
  }
  $listPosition(id: string): ListPosition | null
  $listPosition(id: string, position: ListPosition): void
  $listPosition(
    id: string,
    position?: ListPosition
  ): ListPosition | null | void {
    if (position) {
      this._listPositionCache[id] = {
        time: new Date().getTime(),
        position,
      }
    } else {
      return this._listPositionCache[id]?.position ?? null
    }
  }

  setAppSetting(key: string, value: unknown, type: 'platform' | 'app' = 'app') {
    Settings.set(type, key, value)
  }
  private _userActivityMonitor: UserKeyMonitor | null = null
  _userActivityState: UserActivityState = 'active'
  private _monitorUserActivity() {
    this._userActivityMonitor = new UserKeyMonitor()
    this._userActivityMonitor.start()
    this._userActivityMonitor.on('stateChange', state => {
      debug.info('User Activity State Changed to %s', state)
      this._userActivityState = state
    })
  }
  override _active() {
    debug.info('Active')
  }
}
