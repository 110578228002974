import {
  ContentItem,
  ContentPath,
  CoordinateDimensions,
  DirectionalSignalMap,
  GridResponse,
  availableContentPaths,
  setsByY,
} from '@adiffengine/engine-types'
import { Lightning, Router } from '@lightningjs/sdk'
import defer from 'lodash-es/defer'
import { MainMenu } from '../components/MainMenu'
import { Debugger, defaultBackHandler, isGoodArray } from '../lib'
import { GridCardType } from '../screens'
import {
  AdvancedBoxCard,
  AdvancedGrid,
  AdvancedGridCardConfig,
  AdvancedGridList,
  AdvancedGridTemplateSpec,
  AdvancedWideCard,
} from './AdvancedGrid'

const debug = new Debugger('TrailerHeroPage')

export interface TrailerHeroTypeConfig extends Lightning.Component.TypeConfig {
  initialFocus: 'Grid' | 'Menu'
  SignalMapType: DirectionalSignalMap
  IsPage: true
}

export interface TrailerHeroTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Content: {
    Grid: typeof AdvancedGrid
  }
  content: GridResponse
  path: ContentPath
  fallbackToAnyPath: boolean
}

export class TrailerHero
  extends Lightning.Component<TrailerHeroTemplateSpec, TrailerHeroTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<TrailerHeroTemplateSpec>
{
  public IsPage = true
  Content = this.getByRef('Content')!
  Grid = this.Content.getByRef('Grid')!
  public path: ContentPath = 'details'
  public fallbackToAnyPath = true
  static override _template() {
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
      Content: {
        x: 120 + MainMenu.widthClosed,
        y: 500,
        w: 1920 - (MainMenu.widthClosed + 180),
        h: 540,
        Grid: {
          y: 0,
          type: AdvancedGrid,
          spacing: 40,
          x: 20,
          w: 1920 - (MainMenu.widthClosed + 180),
          h: 1080 - 620,
          signals: {
            left: '_gridLeft',
          },
        },
      },
    }
  }

  private _card: GridCardType = null
  public set card(card: GridCardType) {
    if (card !== this._card) {
      this._card = card
    }
  }

  public get card() {
    return this._card
  }

  // Need to cache the current hero when this
  // page is reused across various pages
  $currentFocusContent(item: ContentItem) {
    debug.info('On Hero', item)
    this.currentHero = item
    this.fireAncestors('$currentFocusContent', item)
  }

  get firstItem(): ContentItem | null {
    if (this.content?.grid[0]) {
      return this.content.grid[0].items[0] ?? null
    }
    return null
  }

  private _setFocusContent(c?: ContentItem) {
    const currentContent =
      c ?? this.currentHero ?? this.cachedHero ?? this.firstItem
    if (currentContent) {
      this.currentHero = currentContent
      this.fireAncestors('$currentFocusContent', currentContent)
    }
  }

  private _currentPage: string | null = null
  private _cache: Record<string, ContentItem> = {}

  set currentHero(item: ContentItem | null) {
    if (this._currentPage != null && item) this._cache[this._currentPage] = item
    this._currentHero = item
  }
  get currentHero() {
    return this._currentHero
  }

  private _currentHero: ContentItem | null = null

  get cachedHero(): ContentItem | null {
    const current = Router.getActiveHash()
    return current ? this._cache[current] ?? null : null
  }

  private cardConfig: AdvancedGridCardConfig = {
    wide: {},
    box: {},
  }

  setCardConfig<T extends keyof AdvancedGridCardConfig>(
    card: T,
    config: AdvancedGridCardConfig[T],
  ) {
    this.cardConfig[card] = config
  }
  $getCardConfig<T extends keyof AdvancedGridCardConfig>(
    card: T,
  ): AdvancedGridCardConfig[T] {
    return this.cardConfig[card]
  }

  public heros: ContentItem[] = []
  private _content: GridResponse | null = null
  set content(grid: GridResponse) {
    const heros = isGoodArray<ContentItem>(grid.heros) ? grid.heros : []
    const items: Lightning.Element.PatchTemplate[] = grid?.grid
      .filter(content => content.items.length > 0)
      .map((content, idx) => {
        const type = content.items[0]!.type
        if (idx === 0) content.items = [...heros, ...content.items]
        const switcher =
          type !== 'movie' || this.card === 'wide'
            ? {
                cardSetup: {
                  spacing: 40,
                  cardsPerRow: 5,
                },
                h: AdvancedGridList.getCardHeight(this.w, AdvancedWideCard),
                card: AdvancedWideCard,
              }
            : {
                cardSetup: {
                  spacing: 20,
                  cardsPerRow: 7,
                },
                h: AdvancedGridList.getCardHeight(this.w, AdvancedBoxCard),
                card: AdvancedBoxCard,
              }

        const args: Parameters<typeof this.Grid.gridItem>[0] = {
          listId: AdvancedGridList.getListId(content),
          content,
          type: AdvancedGridList,
          ...switcher,
          w: this.w,
        }
        return this.Grid.gridItem(args)
      })

    const patch: Lightning.Element.PatchTemplate<AdvancedGridTemplateSpec> = {
      requestId: grid.requestId,
      items,
    }
    this.patch({ visible: true })
    this.Grid.patch(patch)
    debug.info(
      'Patched GridList %s, %s',
      Router.getActiveHash(),
      this.pageRoute,
      patch,
      this,
    )
    this._firstItem = grid.grid[0].items[0] ?? null
    this._setFocusContent(this._firstItem)
  }
  private _firstItem: ContentItem | null = null
  get content() {
    return this._content!
  }

  private _openAnimation: Lightning.types.Animation | undefined = undefined
  get mainMenuOpenAnimation() {
    if (!this._openAnimation) {
      this._openAnimation = this.animation({
        duration: 0.2,
        actions: [
          {
            t: 'Content',
            p: 'x',
            v: { 0: MainMenu.widthClosed + 120, 1: MainMenu.width + 120 },
          },
        ],
      })
    }
    return this._openAnimation
  }
  private _pageRoute: string = 'UNKNOWN_PAGE'
  public get pageRoute(): string {
    return this._pageRoute
  }
  public set pageRoute(value: string) {
    debug.info('Setting page route')
    this._pageRoute = value
  }

  override _init() {
    const menuOpen = this.fireAncestors('$menuOpen')
    this.Content.patch({
      x: menuOpen ? 120 + MainMenu.width : 120 + MainMenu.widthClosed,
    })
    this.stage.application.on('menuOpen', open => {
      if (open) this.mainMenuOpenAnimation.start()
      else this.mainMenuOpenAnimation.stop()
    })
  }

  _gridLeft(coords: CoordinateDimensions | null) {
    if (this.widgets) {
      if (coords !== null && setsByY(this.widgets['mainmenu'])) {
        this.widgets['mainmenu'].setClosestByY(coords)
      }
      defer(() => {
        Router.focusWidget('MainMenu')
      })
    } else {
      return this.signal('left')
    }
  }

  override _active() {
    this.Content.patch({ w: this.w, h: this.h })
    this.stage.application.emit('screenView', 'Trailer Hero', 'trailer_hero')
  }
  override _handleBack() {
    defaultBackHandler(this.application)
    return false
  }
  override _getFocused(): Lightning.Component | undefined {
    return this.Grid
  }
  $contentSelected(content: ContentItem) {
    const destination = content.paths[this.path]
    if (destination) {
      this.fireAncestors('$navigate', destination)
    } else if (this.fallbackToAnyPath) {
      const backupPath = availableContentPaths
        .filter(f => f !== this.path)
        .find(path => {
          content.paths[path] !== undefined
        })
      if (backupPath) {
        this.fireAncestors('$navigate', backupPath)
      } else {
        console.warn(
          'No valid paths for content %s',
          content.title,
          content.paths,
        )
      }
    }
  }
}
