import { DirectionalSignalMap } from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import { PlayControlButton } from './PlayControlButton'
import { TogglePlayButton } from './lib/TogglePlayButton'
import { Debugger } from '../../lib'
import { PlayControlHoverButtons, PlayControlHoverName } from './lib/types'
const debug = new Debugger('AdePlayControls')
export interface AdePlayControlButtonTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Content: {
    PreviousVideo: typeof PlayControlButton
    SkipBack: typeof PlayControlButton
    Play: typeof TogglePlayButton
    SkipForward: typeof PlayControlButton
    NextVideo: typeof PlayControlButton
  }
  hasNext: boolean
  hasPrevious: boolean
}

export interface PlayerControlsSignals extends DirectionalSignalMap {
  fastForwardPressed(): void
  fastForwardReleased(): void
  fastForward(): boolean | void
  rewindPressed(): void
  rewindReleased(): void
  rewind(): boolean | void
  play(): boolean | void
  pause(): boolean | void
  togglePlayPause(): boolean | void
}

export interface AdePlayControlsTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: PlayerControlsSignals
}

export class AdePlayControls
  extends Lightning.Component<
    AdePlayControlButtonTemplateSpec,
    AdePlayControlsTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<AdePlayControlButtonTemplateSpec>
{
  static height = 60
  static width = 180
  private _focusIndex = 1

  PreviousVideo = this.tag('Content.PreviousVideo')
  NextVideo = this.tag('Content.NextVideo')
  Play = this.tag('Content.Play')!
  SkipForward = this.tag('Content.SkipForward')!
  SkipBack = this.tag('Content.SkipBack')!
  buttons: Lightning.Component[] = [this.SkipBack, this.Play, this.SkipForward]

  hoverLookup: Partial<
    Record<PlayControlHoverName, Lightning.Component | null>
  > = {
    [PlayControlHoverButtons.next]: this.NextVideo ?? null,
    [PlayControlHoverButtons.toggle_playpause]: this.Play,
    [PlayControlHoverButtons.previous]: this.PreviousVideo ?? null,
    [PlayControlHoverButtons.skip_back]: this.SkipBack,
    [PlayControlHoverButtons.skip_forward]: this.SkipForward,
  }

  static override _template(): Lightning.Component.Template<AdePlayControlButtonTemplateSpec> {
    return {
      h: 60,
      w: 180,
      Content: {
        x: 0,
        y: 0,
        w: 180,
        h: 40,
        flex: {
          direction: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        },

        // PreviousVideo: {
        //   alpha: 0.0001,
        //   type: PlayControlButton,
        //   action: '$previous',
        //   icon: 'play-skip-back-sharp.svg',
        //   flexItem: {
        //     marginRight: 40,
        //   },
        //   signals: {
        //     hovered: '_buttonHover',
        //   },
        // },
        SkipBack: {
          type: PlayControlButton,
          icon: 'play-skip-back-sharp.png',
          flexItem: {
            marginRight: 60,
          },
          buttonLabel: PlayControlHoverButtons.skip_back,
          signals: {
            hovered: '_buttonHover',
            pressed: '_rewindPressed',
            selected: '_rewind',
            released: '_rewindReleased',
          },
        },
        Play: {
          type: TogglePlayButton,
          signals: {
            hovered: '_buttonHover',
          },
        },
        SkipForward: {
          flexItem: {
            marginLeft: 60,
          },
          type: PlayControlButton,
          buttonLabel: PlayControlHoverButtons.skip_forward,
          icon: 'play-skip-forward-sharp.png',
          signals: {
            hovered: '_buttonHover',
            pressed: '_fastForwardPressed',
            released: '_fastForwardReleased',
            selected: '_fastForward',
          },
        },
        // NextVideo: {
        //   flexItem: {
        //     marginLeft: 40,
        //   },
        //   w: 40,
        //   alpha: 0.0001,
        //   h: 40,
        //   type: PlayControlButton,
        //   action: '$next',
        //   icon: 'play-skip-forward-sharp.svg',
        //   signals: {
        //     hovered: '_buttonHover',
        //   },
        // },
      },
    }
  }

  _buttonHover(button: PlayControlHoverName) {
    const hovered = this.hoverLookup[button]

    const order = [
      PlayControlHoverButtons.skip_back,
      PlayControlHoverButtons.toggle_playpause,
      PlayControlHoverButtons.skip_forward,
    ]
    const current = order.findIndex(t => t === button)
    if (current > -1) {
      this.focusIndex = current
      this.signal('hovered')
    } else {
      console.warn('Got invalid button %s not doing anything.', button)
    }
  }

  set focusIndex(x: number) {
    if (x >= 0 && x < this.buttons.length) {
      this._focusIndex = x
      this._refocus()
    }
  }
  get focusIndex() {
    return this._focusIndex
  }

  private _hasPrevious: boolean = false
  private _hasNext: boolean = false
  set hasPrevious(hasPrevious: boolean) {
    if (this._hasPrevious !== hasPrevious) {
      this.PreviousVideo?.patch({
        alpha: hasPrevious ? 1 : 0,
      })
    }
  }
  set hasNext(hasNext: boolean) {
    if (this._hasNext !== hasNext) {
      this.NextVideo?.patch({
        alpha: hasNext ? 1 : 0,
      })
    }
  }
  override _handleUp(): boolean | void {
    this.signal('up')
  }
  override _handleDown(): boolean | void {
    this.signal('down')
  }

  override _captureRight() {
    if (this.focusIndex === 2) {
      this.signal('right')
    } else {
      this.focusIndex++
    }
  }
  override _captureLeft() {
    if (this.focusIndex > 0) {
      this.focusIndex--
    } else {
      this.signal('left')
    }
  }
  override _getFocused() {
    return this.buttons[this.focusIndex]
  }

  _fastForwardPressed() {
    this.signal('fastForwardPressed')
    this.stage.application.emit('playerControlsShouldHide', false)
  }
  _fastForwardReleased() {
    this.signal('fastForward')
    this.signal('fastForwardReleased')
    this.stage.application.emit('playerControlsShouldHide', true)
  }
  _fastForward() {}
  _rewindPressed() {
    this.signal('rewindPressed')
    this.stage.application.emit('playerControlsShouldHide', false)
  }
  _rewindReleased() {
    this.signal('rewindReleased')
    this.stage.application.emit('playerControlsShouldHide', true)
  }
  _rewind() {
    const response = this.signal('rewind')
    if (response !== true) {
      this.fireAncestors('$skip', -15)
    }
  }
}
