import { Lightning, Router } from '@lightningjs/sdk'

import { RoutedApp } from '@lightningjs/sdk/src/Router/base'

import {
  ContentItem,
  GridContext,
  GridResponse,
  ID,
  MediaDetails,
  MediaDetailsCore,
  MediaDetailsExtra,
  MediaTypes,
  TheGridRowData,
} from './contentTypes'
import { AdeLifecycleStateEvent } from './lifecycle'
import { AuthStatus } from './users'
import { isFunction, isNumber, isString } from './utils'

export interface ListType extends Lightning.Component {
  spacing?: number
  direction: 'column' | 'row'
  itemType: Lightning.Component
  index?: number
  items: any[]
  setIndex(index: number): void
}
export interface HoverComponentSignalMap {
  hovered(coords?: CoordinateDimensions): boolean | void
  unhovered(coords?: CoordinateDimensions): boolean | void
  clicked(coords?: CoordinateDimensions): boolean | void
  pressed(coords?: CoordinateDimensions): boolean | void
  released(coords?: CoordinateDimensions): boolean | void
}
export interface DirectionalSignalMap
  extends Omit<HoverComponentSignalMap, 'clicked'> {
  up(coords?: CoordinateDimensions | null): void | boolean
  down(coords?: CoordinateDimensions | null): void | boolean
  left(coords?: CoordinateDimensions | null): void | boolean
  right(coords?: CoordinateDimensions | null): void | boolean
}

export interface ContentSelectedSignalMap {
  contentSelected(content: ContentItem, playlist?: ContentItem[]): void
  contentFocused(content: ContentItem): void
}
export function hasHeight(x: any) {
  return x && isNumber(x.height)
}
export type ContentSelectedDirectionalSignalMap = DirectionalSignalMap &
  ContentSelectedSignalMap
export interface FocusSignalMap {
  focus(index: number): void
  unfocus(index: number): void
}

export type FocusableMixin<T> = T & {
  focusable(): boolean | null | void
  hovered(component?: Lightning.Element): void
  unhovered(component?: Lightning.Element): void
  clicked(component?: Lightning.Element): void
}

export interface ListPosition {
  index: number
  id?: string
  position: number
  window: number
}
export type LeftRightSignalMap = Omit<DirectionalSignalMap, 'up' | 'down'>
export type UpDownSignalMap = Omit<DirectionalSignalMap, 'left' | 'right'>

export interface WindowPosition {
  currentWindow: number
  currentOffset: number
}
export interface GridEvents {
  updateFocusPosition: (position: WindowPosition) => void
}
export interface ListIndexChange {
  index: number
  previousIndex: number
  dataLength: number
}
export interface Coords {
  x: number
  y: number
}

export interface SetsByY {
  setClosestByY(coords?: CoordinateDimensions | null): void
}
export interface SetsByX {
  setClosestByX(coords?: CoordinateDimensions | null): void
}
export interface FocusCoordinatesAccessor {
  currentFocusCoordinates(): CoordinateDimensions | null
}
export function canGetFocusCoordinates(x: any): x is FocusCoordinatesAccessor {
  return x && isFunction(x.currentFocusCoordinates)
}
export interface XListPosition {
  index: number
  xOffset: number
}
export interface YListPosition {
  index: number
  yOffset: number
}
export type AdvancedListPosition = XListPosition | YListPosition

export type ADListPositions = Record<string, ListPosition>

export interface RestoresState {
  restoreState(state: ListPosition): void
}
export function restoresState(x: unknown): x is RestoresState {
  return x != null && isFunction((x as RestoresState).restoreState)
}

export interface GetsFocusedCoords {
  getFocusedCoords(): CoordinateDimensions | null
}
export function setsByX(x: any): x is SetsByX {
  return x && isFunction(x.setClosestByX)
}
export function getsFocusedCoords(x: any) {
  return x && isFunction(x.getFocusedCoords)
}
export function setsByY(x: any): x is SetsByY {
  return x && isFunction(x.setClosestByY)
}
export interface HorizontalList {
  setClosestByX(coords?: CoordinateDimensions | null): void
  getFocusedCoords(): CoordinateDimensions | null
}

export type HorizontalListComponent = new (...args: any[]) => HorizontalList
export interface ListSpaceHelpers {
  getCurrentIndexCoordinates(): CoordinateDimensions | null
}

export interface HorizontalListPatchTemplate
  extends Omit<Lightning.Element.PatchTemplate, 'type'> {
  type: HorizontalListComponent
}
export interface Dimensions {
  width: number
  height: number
}
export interface LightningDimensions {
  h: number
  w: number
}
export type GetConstructorArgs<T> = T extends new (...args: infer U) => any
  ? U
  : never

export interface BaseNavigationPayload {
  path: string
  type: 'extra' | 'episode'
  [key: string]: any
}

export interface EpisodeNavigationPayload extends BaseNavigationPayload {
  type: 'episode'
  episodeNumber: ID
  seasonNumber: ID
  content?: ContentItem
}
export interface ExtraNavigationPayload extends BaseNavigationPayload {
  type: 'extra'
  id: ID
  content?: ContentItem
}
export type NavigationPayload =
  | EpisodeNavigationPayload
  | ExtraNavigationPayload

export interface ActionConfig {
  action: keyof Lightning.Component.FireAncestorsMap
  payload?: any // Need to figure this one out....
}
export interface DetailsParams {
  type: Omit<MediaTypes, 'all'>
  id: ID
}
export type Direction = 'horizontal' | 'vertical'
export type RemoteDirection = 'up' | 'down' | 'left' | 'right'
export interface DetailsResponse {
  meta: ContentItem
  context: DetailsContext
}
export interface GridPosition {
  mainIndex: number
  crossIndex: number
  lines: number
  dataLength: number
  previousCrossIndex: number
  previousIndex: number
  previousMainIndex: number
}

export interface HorizontalNavSignalMap {
  right(coords: CoordinateDimensions | null): void
  left(item: CoordinateDimensions | null): void
}
export interface AdePlayerEventMap extends Lightning.Component.EventMap {
  currentTime(x: number): void
  duration(x: number): void
  paused(x: boolean): void
  error(e: MediaError | null): void
  content(content: ContentItem | null): void
  canPlay(playable: boolean): void
  ended(ended: boolean): void
  controlsHidden(hidden: boolean): void
  adActive(active: boolean): void
}

export type SlowAdePlayerEventMap = Omit<AdePlayerEventMap, 'currentTime'>
export interface ParsedMedia {
  content?: MediaDetailsCore
  trailer?: MediaDetailsExtra
  extras?: MediaDetailsExtra[]
}
export type PlayerEvent = keyof AdePlayerEventMap
export type PlayerState = {
  [K in keyof Omit<
    AdePlayerEventMap,
    'txLoaded' | 'txUnloaded' | 'txError'
  >]: Parameters<AdePlayerEventMap[K]>[0]
}

export type SlowPlayerState = Omit<PlayerState, 'currentTime'>

export interface PlayerEmitter {
  currentTime: number
  userPlayState: 'playing' | 'paused'
  playerPlaused: boolean
  duration: number
}
export interface DetailsContext {
  params: DetailsParams
}

export interface TmdbGridResponse extends GridResponse {
  grid: TheGridRowData[]
  heros?: TheGridRowData
  context: GridContext
}
export interface RowButtonConfig {
  buttonText: string
  action: ActionConfig | null
  type?: object
  w?: number
  h?: number
}
export interface ButtonConfig<T = undefined> {
  action: ActionConfig
  buttonText: string
  payload: T
}
export interface ActionableRowButtonConfig {
  action: ActionConfig
  buttonText: string
}
export function isActionableRowButtonConfig(
  x: any,
): x is ActionableRowButtonConfig {
  return x && isString(x.buttonText) && x.action && isString(x.action.action)
}

export interface GridData {
  window: number
  id: ID
}

export type CoordinateDimensions = Coords &
  Dimensions & {
    gridData?: GridData
  }
export interface MouseCoordinateDimensions extends CoordinateDimensions {
  clientX: number
  clientY: number
}

export type ColorHue = {
  50: string
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800: string
  900: string
}
export type ColorHueColorized = {
  [key in keyof ColorHue]: number
}

export interface Palette {
  primaryBackground: string
  shadow: string
  errorText: string
  goodText: string
  background: string
  backgroundGradient: string
  text: string
  cardHighlightText: string
  buttonBackground: string
  buttonText: string
  buttonBorder: string
  buttonHighlightBackground: string
  buttonHighlightText: string
  buttonHighlightBorder: string
  primaryHighlight: string
  currentHighlight: string
  currentHighlightText: string
  highlights: ColorHue
  darks: ColorHue
}

export interface BoxCardConfig {
  radius: number
  textHighlightColor?: string
}
export interface SimpleCardConfig {
  radius: number
  imageRadius: number
  largeRadius: number
  backgroundColor: string
  stroke: number
  shadowColor: string | number
}
export interface MainMenuButtonConfig {
  backgroundColor: string
  radius: number
}
export interface VideoCardConfig {
  radius: number
  textHighlightColor?: string
  vignetteColor: string
}
export interface HeroWidgetItemConfig {
  colorLeft: string
  colorRight: string
}

export interface BaseConfig {
  radius: number
}
export interface PlayControlConfig {
  playBackground: string
  backgroundAlphaUnfocused: number
  backgroundAlphaFocused: number
  scale: number
}
export interface MainMenuConfig {
  radius: number
  backgroundColor: string
  icon?: string
  logo?: string
}
export interface FullPageBackgroundConfig {
  backgroundColor?: string
  highlightColor?: string
  logo?: string
  logoColor?: string | number
}

export type PaletteStringColors = Omit<Palette, 'highlights' | 'darks'>
export type PaletteHuePalettes = Pick<Palette, 'highlights' | 'darks'>
export interface FontConfig {
  name: string
  Regular: string
  Text: string
  Bold: string
  ExtraBold: string
}

export interface ThemeConfig {
  name: string
  logo: string
  logo_size: number
  palette: Palette
  fonts: FontConfig

  components: {
    FullPageBackground: FullPageBackgroundConfig
    PlayControlConfig: PlayControlConfig
    ButtonConfig: BaseConfig
    BoxCardConfig: BoxCardConfig
    SimpleCardConfig: SimpleCardConfig
    VideoCardConfig: VideoCardConfig
    HeroWidgetItemConfig: HeroWidgetItemConfig
    MainMenuConfig: MainMenuConfig
    MainMenuButtonConfig: MainMenuButtonConfig
  }
  colors?: Record<string, string>
}

export interface Coordinated {
  x?: number
  y?: number
  finalX?: number
  finalY?: number
  isRoot?: boolean
  parent?: Coordinated | null
  finalH?: number
  finalW?: number
}
export interface Finals {
  finalH: number
  finalW: number
  finalX: number
  finalY: number
}
export interface CardListPosition {
  index: number
  offset: number
  currentWindow: number
  currentRowOffet: number
}
export interface ComponentDimensions {
  x: number
  y: number
  h: number
  w: number
}

export interface AppTemplateEvents extends Router.App.EventMap {
  themeChanged(theme: ThemeConfig, oldTheme?: ThemeConfig): void
  playlistEnded(): void
  menuOpen(open: boolean, duration: number): void
  currentStaticHero(item: ContentItem): void
  playerMediaChanged(item: MediaDetails | null): void
  playerContentItemChanged(item: ContentItem): void
  playerState(item: SlowPlayerState): void
  playerTime(time: number, duration: number): void
  palette(palette: SimplePalette): void
  currentPlaybackItem(item: ContentItem): void
  authStatusChanged(status: AuthStatus): void
  lifecycle(stateUpdate: AdeLifecycleStateEvent): void
  playerError(error: Error): void
}
export interface AppTemplateConfig extends RoutedApp.TypeConfig {
  EventMapType: AppTemplateEvents
}
export interface AppTemplateType extends Router.App.TypeConfig {
  EventMapType: AppTemplateEvents
}
export interface SimplePalette {
  background: string
  foreground: string
  focus: string
  gradient: string
}

export interface RgbColor {
  r: number
  g: number
  b: number
}
export interface HslColor {
  h: number
  s: number
  l: number
}

export type AppFontFaces = 'Text' | 'Regular' | 'Bold' | 'ExtraBold'
