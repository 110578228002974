import { Launch } from '@lightningjs/sdk'
import { App } from './mitu_app.js'

export function LaunchApp(
  appSettings: any,
  platformSettings: any,
  appData = {}
) {
  console.info(
    'Launching with settings',
    JSON.stringify({ appSettings, platformSettings, appData }, null, 2)
  )
  return Launch(App, appSettings, platformSettings, appData)
}
export function attachApp(app: ReturnType<typeof LaunchApp>) {
  const canvas = app.stage.getCanvas()
  console.log('Got Canvas and launching.', document, canvas)
  if (document && document.body) {
    console.log('Attaching to document')
    document.body.appendChild(canvas)
  } else if (window) {
    console.log('Attaching to window')
    window.addEventListener('load', () => {
      document.body.appendChild(canvas)
    })
  }
}
